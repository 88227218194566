<template>
  <v-dialog v-model="newDialog" persistent max-width="500">
    <v-card>
      <v-card-title>
        new rating for &nbsp;
        <span style="color: rgb(136, 78, 78)">
          {{ myData.fullname | capitalize }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-textarea
          outlined
          label="Comment"
          v-model="create.comment"
          counter="277"
          maxlength="277"
        ></v-textarea>

        <div class="d-flex mb-6">
          <div style="padding-top: 10px">Rating Provider:</div>
          <div style="padding-left: 20px">
            <v-rating label="Rating" v-model="create.rating"></v-rating>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.create.$invalid"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  mixins: [Utils, validationMixin],
  props: {
    newDialog: Boolean,
    myData: { type: Object },
  },
  data() {
    return {
      create: {
        comment: null,
        rating: 0,
      },
    };
  },
  validations: {
    create: {
      comment: {
        required,
      },
      rating: {
        required,
        minValue: minValue(1),
      },
    },
  },
  methods: {
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let subscribersid = this.myData.subscribersid;
      let comment = this.create.comment;
      let rating = this.create.rating;
      let data = { subscribersid, comment, rating };
      console.log("data: ", data);

      // console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
