<template>
  <v-dialog v-model="editDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title> update rating </v-card-title>
      <v-card-text>
        <v-textarea
          outlined
          label="Comment"
          v-model="create.comment"
          counter="277"
          maxlength="277"
        ></v-textarea>

        <div class="d-flex mb-6">
          <div style="padding-top: 10px">Rating Provider:</div>
          <div style="padding-left: 20px">
            <v-rating label="Rating" v-model="create.rating"></v-rating>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.create.$invalid"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  mixins: [Utils, validationMixin],
  props: {
    editDialog: Boolean,
    myData: { type: Object },
  },
  data() {
    return {
      disabled: false,
      myPix: null,
      pix: 0,
      loading: false,
      regionlist: [],
      districtlist: [],
      create: {
        comment: this.myData.comment,
        rating: this.myData.rating,
      },
    };
  },
  validations: {
    create: {
      comment: {
        required,
      },
      rating: {
        required,
        minValue: minValue(1),
      },
    },
  },
  watch: {},
  created() {
    // this.find();
  },
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let comment = this.create.comment;
      let rating = this.create.rating;
      let id = this.myData.id;

      let data = {
        comment,
        rating,
      };
      let payload = { id: id, data: data, state: false };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
